.list-unstyled {
    list-style-type: none;
}

.enrollment-users {
    max-height: 30rem;
    overflow-y: auto;
    padding: 1.5rem;
    background:#f9f9f9;
    border-radius: .3rem;
    width: 100%;
    height: 100%;
}

// FIXME ez mehetne globalba majd
.MuiCardHeader-root {
    color: #fff;
    background-color: #1C8038;
}