.category {
    background-color: #f6f6f6 !important;
}

.subcategory {
    margin-left: 30px !important;
}

div[col-id="ops"] .ag-header-cell-label {
  margin-left: 30px;
}