$mainContentBg: #f9f9f9;
$scrollbar: #c9c9c9;
$white: #ffffff;
$whiteSmoke: #f6f6f6;
$black: #000000;
$lightGrey: #e0e0e0;
$mediumGrey: #616161;
$darkGrey: #444444;
$grey: #9d9d9d;
$lightOrange: #efab6a;
$lightBlue: #8bc0ce;
$lightGreen: #85c79d;
$lightGreenBorder: #add9bd;
$lightWhiteBorder: #e0e0e0;
$purple: #b681ad;
$lightBrown: #efab6a5c;
$blueishWhite: #cde4ea;
$lightWarn: #f8dd29;
$accent: #d6ecde;
$primary: #1C8038;
$green: #009628;
$greenLight: #5db47c;
$warn: #e57681;
$error: #ff3333;
$darkError: #E57681;
$disabled: #a7dbb9;
$yellowRequiredBg: #FEF8D2;
$blue: #6DAC81;
$darkPrimary: #6DAC81; //FIXME: az új primary-hez illő sötétebb szín
$darkBlue: #2b5667;
$orange: #FFAA59;
$lightPrimary: #DDECE1;
$red: #f44336;

:export {

  mainContentBg: $mainContentBg;
  scrollbar: $scrollbar;
  white: $white;
  whiteSmoke: $whiteSmoke;
  black: $black;
  lightGrey: $lightGrey;
  mediumGrey: $mediumGrey;
  darkGrey: $darkGrey;
  grey: $grey;
  orange: $orange;
  lightOrange: $lightOrange;
  lightBlue: $lightBlue;
  lightGreen: $lightGreen;
  lightGreenBorder: $lightGreenBorder;
  lightWhiteBorder: $lightWhiteBorder; // $light-grey
  purple: $purple;
  lightBrown: $lightBrown;
  blueishWhite: $blueishWhite;
  darkPrimary: $darkPrimary;
  lightWarn: $lightWarn;
  accent: $accent;
  primary: $primary;
  green: $green;
  warn: $warn;
  error: $error;
  darkError: $darkError;
  disabled: $disabled;
  yellowRequiredBg: $yellowRequiredBg;
  blue: $blue;
  darkBlue: $darkBlue;
  orange: $orange;
  lightPrimary: $lightPrimary;
  red: $red;
}