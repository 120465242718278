@import "../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/legacy/ag-theme-material-v22-compat";
@import "./styles/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

html,
body,
#root {
    height: 100vh;
    background: url("../src/img/background.svg");
    background-size: cover;
}

* {
    font-family: "Roboto", sans-serif !important;
}

.ag-icon,
.ag-checkbox-input-wrapper {
    font-family: "agGridMaterial" !important;
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
    color: $primary !important;
}

/*
.ag-header-cell-label {
  justify-content: center;
}
*/

div[col-id="operations"] .ag-header-cell-label,
[col-id="operations"] .ag-cell-wrapper,
[col-id="changes"] .ag-cell-wrapper,
[col-id="content"] .ag-cell-wrapper {
    justify-content: center !important;
    text-align: center !important;
}

.ag-react-container {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

div.ck-content {
    min-height: 10rem;
}

.ag-theme-material .ag-cell-inline-editing {
    border-color: #008554 !important;
    border-color: var(--ag-input-focus-border-color, var(--ag-material-accent-color, #008554)) !important;
}

.ag-cell-focus {
    border: none !important;
}

.makeStyles-wrapper-6 .Mui-disabled {
    background-color: #a7dbb9 !important;
}

.MuiBadge-badge {
    position: "relative" !important;
}

.Mui-selected {
    font-weight: bold !important;
    //white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0;
}

.MuiTabs-indicator {
    background-color: $primary !important;
    height: 5px !important;
}

.MuiTabs-fixed .MuiButtonBase-root:not(.Mui-selected) {
    background-color: $white !important;
    color: $primary !important;
    opacity: 1 !important;
}

div.MuiTabs-root .MuiButtonBase-root .MuiTab-wrapper {
    font-weight: bold !important;
}

div.MuiTabs-root {
    background-color: $primary !important;
}

.MuiMenu-list {
    background-color: $white;
    color: $primary;
}
.MuiFormLabel-asterisk {
    color: red;
}
.ag-react-container .ag-grid-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.MuiInputBase-root {
    font-size: 14px !important;
}

.MuiMenuItem-root {
    font-size: 0.9rem !important;
}

.MuiListItemIcon-root {
    min-width: 25px !important;
}

.MuiAccordionSummary-root .MuiAccordionSummary-expandIcon {
    padding-top: unset;
    padding-bottom: unset;
}

.MuiInputBase-input {
    font-size: 14px !important;
}

.MuiInputLabel-root {
    font-size: 14px !important;
}

.MuiCardHeader-title {
    font-size: 1rem !important;
    color: $darkPrimary;
    margin: 6px 0 !important;
}

.MuiCardHeader-root {
    background-color: $lightPrimary !important;
    padding: 0 16px !important;
    min-height: 40px !important;
}

.MuiSelect-root {
    padding-top: 10.5px !important;
    padding-bottom: 10.5px !important;
}

.MuiFormControl-marginNormal {
    margin-top: 8px !important;
    margin-bottom: 2px !important;
}

li.MuiListItem-button:hover {
    background-color: $lightPrimary;
    color: $darkPrimary;
}

#menu-appbar .MuiPaper-root.MuiMenu-paper ul.MuiMenu-list,
#menu-appbar .MuiPaper-root.MuiMenu-paper {
    background-color: $white !important;
}

.MuiOutlinedInput-root[required],
.multiselect-autocomplete-required .MuiAutocomplete-inputRoot,
.MuiInputBase-root input[required] {
    background-color: $yellowRequiredBg;
}

li.MuiListItem-root.Mui-selected,
li.MuiListItem-root.Mui-selected:hover {
    background-color: $primary;
    color: $white;
}

.rbc-toolbar {
    background-color: $white;
    color: #1c8038;
    font-weight: bold;
    border: 1px solid #ddd;
    border-bottom: unset;
    margin-bottom: 0;
}

.rbc-toolbar button {
    border: unset;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
    background-color: #1c8038;
}

.rbc-show-more {
    text-decoration: none;
    color: $primary;
}

.rbc-show-more:hover {
    text-decoration: underline;
    color: $greenLight;
}

figure img {
    max-width: 100%;
    height: auto;
}

figure {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

button.MuiButton-containedPrimary,
button.MuiButton-containedPrimary {
    background-color: $primary !important;
}

button.btn-green {
    background-color: $greenLight !important; /*#5db47c*/
    color: $white;
}

button.btn-gray {
    background-color: $grey !important; /*#C9C9C9*/
    color: $white;
}

button.btn-red,
.MuiBadge-root.btn-red span {
    background-color: $darkError !important; /*#80231c*/
    color: $white;
}

.MuiPickersBasePicker-container {
    & .Mui-selected {
        & .MuiSvgIcon-root {
            color: #ffffff;
        }
    }
}
.MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error.MuiFormHelperText-filled {
    white-space: normal;
}

.ag-tooltip {
    color: #1C8038 !important;
    border: 1px solid #dadde9 !important;
    font-size: 0.75rem !important;
    box-shadow: none !important;
    border-radius: 4px !important;
    line-height: 1.4em !important;
    padding: 4px 8px !important;
}