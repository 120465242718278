.tree-view {
    padding-left: 0;
}

.tree-view .tree-view {
    padding-left: 2rem;
}
.tree-view li {
    cursor: pointer;
    font-size:1rem;
}
.tree-view li span:hover, .tree-view li.active span {
    font-weight: 600;
    color: #5db47c
}
.tree-view li:not(.open) > ul {
    display: none;
}
.tree-view .icon {
    margin-right: .5rem;
    font-size: .7em;
    margin-top: -.2em;
}
/*.tree-view li.open > .icon {
    transform: rotate(90deg);
}*/